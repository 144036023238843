import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentModal.figmaUrl.android} codeUrl={checklists.componentModal.codeUrl.android} checklists={checklists.componentModal.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`The Modal component is a dialog window that is displayed on top of the main content. It is used to display content that is not essential to the main interaction flow but requires the user’s attention.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/modal/android_modal.gif",
            "alt": "modal Legion android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has 4 variants of modals :`}</p>
    <ol>
      <li parentName="ol">{`Modal With Title`}</li>
      <li parentName="ol">{`Modal with Description`}</li>
      <li parentName="ol">{`Modal With Button`}</li>
      <li parentName="ol">{`Modal With Show Close`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <h3>{`Basic`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
  LgnModal.setup(requireContext()) {
      ...
      orientation = LgnModal.Orientation.VERTICAL
      header = LgnModal.HeaderView.None
      setContent<LayoutModalBinding> {
        ...
      }
      ...
  }.showNow(childFragmentManager, "modal")
}



...
`}</code></pre>
    <h3>{`Modal With Title`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
  LgnModal.setup(requireContext()) {
      ...
      orientation = LgnModal.Orientation.VERTICAL
      header = LgnModal.HeaderView.Default(title = "title")
      setContent<LayoutModalBinding> {
        ...
      }
      ...
  }.showNow(childFragmentManager, "modal")
}



...
`}</code></pre>
    <h3>{`Modal With Description`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
  LgnModal.setup(requireContext()) {
      ...
      orientation = LgnModal.Orientation.VERTICAL
      header = LgnModal.HeaderView.Default(description = "description")
      setContent<LayoutModalBinding> {
        ...
      }
      ...
  }.showNow(childFragmentManager, "modal")
}



...
`}</code></pre>
    <h3>{`Modal With Button`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
  LgnModal.setup(requireContext()) {
      ...
      orientation = LgnModal.Orientation.VERTICAL
      header = LgnModal.HeaderView.Default(title = "title")
      setContent<LayoutModalBinding> {
        ...
      }
      ...
      addButton(LgnPrimaryFitButton(requireContext())) {
         text = "Close"
         setOnClickListener { dismiss() }
      }
      ...
  }.showNow(childFragmentManager, "modal")
}



...
`}</code></pre>
    <h3>{`Modal With Show Close`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
  LgnModal.setup(requireContext()) {
      ...
      orientation = LgnModal.Orientation.VERTICAL
      header = LgnModal.HeaderView.Default(showClose = true)
      setContent<LayoutModalBinding> {
        ...
      }
      ...
  }.showNow(childFragmentManager, "modal")
}



...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set view type for header`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Orientation Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`orientation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set footer button orientation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setContent<T : ViewBinding>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Configure content views, can modify your views at provided lambda`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Button on Footer Bottom Sheet with Builder Pattern, and maximum users can add button. is 3 button, and when more than 3 will throw `}{`[IndexOutOfBoundsException]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismiss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dismiss `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismiss Modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismiss Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setModalListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Receive listener on modal dismissed`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      